import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  arrondi(nombre){
    return Math.round(nombre);
  }

  public arrayColorSkills = [
   "rgba(141, 189, 226,0.6)",
   "rgba(244, 183, 143,0.6)",
    "rgba(161, 205, 155,0.6)",
    "rgba(237, 162, 169,0.6)",
   "rgba(181, 133, 232,0.6)",
    "rgba(161, 205, 155,0.6)",
    "rgba(161, 205, 155,0.6)",
    "rgba(161, 205, 155,0.6)"
  ]

  public slideOpts = {
    initialSlide: 1,
    slidesPerView: 2.2,
    speed: 400,
    breakpoints: {
      // when window width is <= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 5
      },
      480: {
        slidesPerView: 2.2,
        spaceBetween: 10
      },
      640: {
        slidesPerView: 4.2,
        spaceBetween: 30
      },
      1200: {
        slidesPerView: 6.2,
        spaceBetween: 30
      }
    }
  };
}
