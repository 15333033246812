import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class NewCoachingService {
  data;
  skills_arr;
  constructor(private api_service:ApiService) { }
  // getSkillCategories = (token, commercial_id)=>{
  //   return this.getSkillCategories(token, commercial_id);
  // }

  async getSkillCategories (sgid) {
     return this.api_service.skills$(sgid);
    //  .subscribe(res=>{
    //   console.log('NewCoachingService',res);
    //   return res;
    // })
    // const res = await this.api_service.skills$(sgid);
    // return res;
  }

//   getCategoryLength =()=>{
//     return this.skills_arr.length;
//   }
//   getSingleFamily =(id)=>{
//     return this.returnID(id);
//   },
//   getSkillsArr =()=>{
//     return this.skills_arr;
//   }
//   returnID = (id)=>{
//     return this.data[id];
//   }

//    getSkillCategories = (token, commercial_id)=>{
// //     var dataObject = new Array();
// //       dataObject.push(response.data);
// //       data = new Array();
// //       skills_arr = new Array();
// //       var subSkills = new Array();
// //       var counterInLoop = 0;
// //       var indexP = 0;
// //         var SubIndexP = 0;
// //       for (var key of Object.keys(response.data)) {
// //          // reset subskills
// //          indexP = indexP + 1;
// //          //

// //           if (response.data[key][Object.keys(response.data[key])[0]][0].family_skill_name_trad) {
// //             data[counterInLoop] = {'id_category_skills' : response.data[key][Object.keys(response.data[key])[0]][0].family_skill_name_trad, 'skills' : [], 'under_skill_name' : [], 'id_category_number' : counterInLoop};
// //           }else {
// //             data[counterInLoop] = {'id_category_skills' : key, 'skills' : [], 'under_skill_name' : [], 'id_category_number' : counterInLoop};
// //           }
// //          for(var skills_key of Object.keys(response.data[key])){

// // SubIndexP = SubIndexP + 1;
// //  if (response.data[key][skills_key][0].skill_name_trad) {
// // data[counterInLoop].skills.push({key:skills_key,trad:response.data[key][skills_key][0].skill_name_trad});
// //     skills_arr.push({'name' : response.data[key][skills_key][0].skill_name_trad, 'skill_id' : response.data[key][skills_key][0].skill_id, 'family_id' : response.data[key][skills_key][0].family_skill_id });
// //  }else {
// //    data[counterInLoop].skills.push({key:skills_key,trad:skills_key});
// //     skills_arr.push({'name' : skills_key, 'skill_id' : response.data[key][skills_key][0].skill_id, 'family_id' : response.data[key][skills_key][0].family_skill_id });
// //  }

// //            console.log(response.data[key][skills_key]);
// //            subSkills = new Array();
// //            for(var underskills = 0; underskills < response.data[key][skills_key].length; underskills++){
// //              subSkills.push(response.data[key][skills_key][underskills]);
// //            }
// //             data[counterInLoop].under_skill_name.push(subSkills);
// //          }
// //          counterInLoop++;
// //        }

// //       promiseCategories.resolve(data);
//     }
}
