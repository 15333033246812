import { trigger, style, transition, animate, keyframes, query, stagger,state } from '@angular/animations';

export const ListAnimationY = trigger('listAnimation', [
   transition('* => *', [

      query(':enter', style({ opacity: 0, height: '!' }), { optional: true }),

      query(':enter', stagger('70ms', [
         animate('1s ease-in', keyframes([
            style({ opacity: 0, transform: 'translateY(-50px)', offset: 0 }),
            style({ opacity: .5, transform: 'translateY(35px)', offset: 0.3 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
         ]))]), { optional: true })
   ])
]);



export const fade = trigger('fade', [
      state('inactive', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      transition('* <=> *', [
      animate(2000)
      ])
]);

export const pulse = trigger('pulse', [
      state('inactive', style({ transform:'scale(.6)' })),
      state('active', style({ transform:'scale(.7)' })),
      transition('* <=> *', [
            animate('3s ease-in', keyframes([
                  style({ transform:'scale(.6)'}),
                  style({ transform:'scale(.7)'}),
                  style({ transform:'scale(.6)'})
            ]))
      ])
]);

export const ListAnimationX = trigger('listAnimationX', [
   transition('* => *', [

      query(':enter', style({ opacity: 0, height: '!' }), { optional: true }),

      query(':enter', stagger('70ms', [
         animate('1s ease-in', keyframes([
            style({ opacity: 0, transform: 'translateX(-50px)', offset: 0 }),
            style({ opacity: .5, transform: 'translateX(35px)', offset: 0.2 }),
            style({ opacity: 1, transform: 'translateX(0)', offset: 1.0 }),
         ]))]), { optional: true })
   ])
]);

export const FadeDownAnimation = trigger('fadeDown', [
   transition('* => in', [
      style({ opacity: 0, height: '!', transform: 'translateY(-15px)' }),
      animate(300, style({ opacity: 1, transform: 'translateY(0px)' }))
   ])
]);

export const FadeUpAnimation = trigger('fadeUp', [
   transition('* => in', [
      style({ opacity: 0, height: '!', transform: 'translateY(15px)' }),
      animate(300, style({ opacity: 1, transform: 'translateY(0px)' }))
   ])
]);

export const ZoomAnimation = trigger('zoomIn', [
   transition('* => in', [
      animate('0.4s ease-in', keyframes([
         style({ opacity: 0, height: '!', transform: 'scale(0)' }),
         style({ opacity: 1, transform: 'scale(1.2)' }),
         style({ opacity: 1, transform: 'scale(1)' })
      ]))
   ])
]);

export const RotateAnimation = trigger('rotateIn', [
   transition('* => in', [
      animate('0.25s ease-in', keyframes([
         style({ opacity: 1, height: '!', transform: 'rotate(-180deg)' }),
         style({ opacity: 1, transform: 'rotate(0deg)' })

      ]))
   ])
]);
