import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { environment as env, environment } from '../../environments/environment';
import { Observable, Subscription } from 'rxjs';
import {map, switchMap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class GradeGuard implements CanActivate {
  profile;
  constructor(private auth:AuthService,private router:Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const expectedRoleAdm = 1;
    const expectedRoleDir = 3;
    const expectedRoleE2 = 4;
     return this.auth.user$.pipe(
      map(userProfile => {console.log('iduser', userProfile[env.envKey+ '/grade_user']);this.profile = userProfile[env.envKey+ '/id_user'];return userProfile[env.envKey + '/grade_user']}),
      map((isInRole : number) => {
        console.log('isInRole',isInRole);
        if (isInRole == expectedRoleAdm || isInRole == expectedRoleDir ) {
          console.log('TRUE');
          return true;
        }else if (isInRole == expectedRoleE2) {
          this.router.navigate(['/e2/' + this.profile]);
          console.log('FALSE');
          return false;
        } else {
          //We extract user sgid
          this.router.navigate(['/dashboard/' + this.profile ]);
          console.log('FALSE');
          return false;
        }
      })
      );

  }


}
