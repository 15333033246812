<ion-card class="ion-text-start" button [routerLink]="['/dashboard',sales.id_user]" >
    <!-- <img src="https://placem.at/places?overlay_color=eb7a85&w=600&h=290"> -->
    <ion-card-header>
      <ion-card-subtitle >{{sales.role_name}}</ion-card-subtitle>
      <ion-card-title>{{sales.first_name}} {{sales.last_name}}</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <progress-bar [progress]="utils_service.arrondi(sales.pourcentage)" [color-degraded]="{'0': '#00cbcb',  '15': '#f9c3d3', '25': '#fd8c8e', '50': '#B7B722','74': '#3CBC3C'}">
      </progress-bar>
    </ion-card-content>
  </ion-card>