import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-viewsession',
  templateUrl: './viewsession.component.html',
  styleUrls: ['./viewsession.component.css']
})
export class ViewsessionComponent implements OnInit {
  user_sgid = this.navParams.get('user_sgid');
  skill_id = this.navParams.get('skill_id');
  session_id= this.navParams.get('session_id');
  questions: any;

  constructor(private navParams: NavParams,private api:ApiService,private modalController:ModalController) { }

  ngOnInit(): void {

    this.api.getSKillsNotes$(this.user_sgid,this.skill_id,this.session_id ).subscribe(res=> {

      const newArr = res.map(obj => {
      obj.note = parseInt(obj.note);

        return obj;
      });
      console.log('resultat',newArr);
      this.questions = newArr;
    })
  }
 public retInt (val){
  return parseInt(val);
 }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
