import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ErrorComponent } from './pages/error/error.component';
import {ResourcesComponent} from'./pages/resources/resources.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { InternationalizationComponent } from './components/internationalization/internationalization.component';
import { AdminComponent } from './pages/admin/admin.component';
import {GradeGuard} from './services/grade.guard';
import { E2Component } from './e2/e2.component';
import { E2detailComponent } from './e2detail/e2detail.component';

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  preloadingStrategy: PreloadAllModules
};
const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'dashboard/:user_sgid',
  //   component: DashboardComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard,GradeGuard],
  },
  {
    path: 'resources',
    component: ResourcesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/translations',
    component: InternationalizationComponent,
    canActivate: [AuthGuard],
  },


  {
    path: 'e2/:user_sgid',
    component: E2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'e2detail/:country_id',
    component: E2detailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  { path: '',   redirectTo: '/home', pathMatch: 'full' },
  { path: 'dashboard/:user_sgid', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) ,
  canActivate: [AuthGuard]}


];

@NgModule({
  imports: [RouterModule.forRoot(routes,routingConfiguration)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
