


<ion-toolbar color="secondary">
    <ion-buttons slot="end">
        <button (click)="dismiss()">
            <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
        </button>
    </ion-buttons>
    <ion-title style="text-transform:uppercase;">Add a manager profile</ion-title>
</ion-toolbar>
<ion-content>


    <form [formGroup]="ionicForm"  novalidate>
        <ion-item lines="full">
          <ion-label position="floating">Name</ion-label>
          <ion-input formControlName="name" type="text" required></ion-input>
        </ion-item>
        <ion-item lines="full">
          <ion-label position="floating">Firstname</ion-label>
          <ion-input formControlName="firstname" type="text" required></ion-input>
        </ion-item>
        <ion-item lines="full">
          <ion-label position="floating">Country</ion-label>
          <ion-input readonly  formControlName="country" ([ngModel])="data.country" [value]="data?.country"  type="text" required></ion-input>
        </ion-item>
        <ion-item lines="full">
          <ion-label position="floating">Mail Adress</ion-label>
          <ion-input type="text" formControlName="mail" required></ion-input>
        </ion-item>
        <ion-item lines="full">
            <ion-label position="floating">SGID</ion-label>
            <ion-input formControlName="sgid" type="text" required></ion-input>
          </ion-item>
        </form>



        <ion-row>
          <ion-col>
            <ion-button (click)="submitForm()" [disabled]="ionicForm.invalid"  type="submit" color="danger" expand="block">Create this new manager</ion-button>
          </ion-col>
        </ion-row>

</ion-content>
