import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { ModalController } from '@ionic/angular';
import { environment as env } from '../../../environments/environment';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-addsales',
  templateUrl: './addsales.component.html',
  styleUrls: ['./addsales.component.css']
})
export class AddsalesComponent implements OnInit {
  priorities: any;
  data: any;
  ionicForm : FormGroup;
  subskills: any;
  urlEnv = env.envKey;
  questions = [];
  segmentModel = 1;
  constructor(private router: Router,public formBuilder: FormBuilder,private auth:AuthService,private api_service:ApiService,private modalController:ModalController) {
    this.ionicForm = this.formBuilder.group({
      role: ['', [Validators.required]],
      name: ['', [Validators.required]],
      firstname: ['', [Validators.required]],
      mail: ['', [Validators.required]],
      country: ['', [Validators.required]],
      sgid: ['', [Validators.required]]
    })



   }

  ngOnInit(): void {
    this.auth.user$.subscribe(res => {
      this.api_service.initializeSession$(res[this.urlEnv + '/id_user']).subscribe(res=> {
        console.log('initializeSession',res);
       this.data = res;

      });

  })

  }

  questionChanged(question,note) {
    question.note = note;
    console.log(question);
  }

  // selectData(skill) {
  //   console.log(skill);
  //   this.subskills = skill.subskills;
  // }

  getQuestions(subskill) {
    this.questions = subskill.questions;

  }

  submitForm() {
    let arrayNotFilled = [];
    let faData = [];
    console.log(this.ionicForm.value);
    let noteFilled = true;
    this.data.skills.forEach(function (subskills) {
      subskills.subskills.forEach(function (value) {
        console.log(value.note);
        if(Number.isInteger(value.note)) {
          faData.push(value);
        }else {
          arrayNotFilled.push(value);
          noteFilled = false;
        }
      });
    });
    if(noteFilled == true){
      console.log(this.data.skills);
      let objuser = this.ionicForm.value;
      objuser.faData = faData;
      this.api_service.setfa$(objuser).subscribe(res=> {
        console.log('setfa',res);
        this.router.navigate(['/home']).then(() => {
          window.location.reload();
        });
      })
    }else {
      alert("Please fill in all the notes before to create the user");
    }

  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
