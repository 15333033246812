import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { LoaderService } from '../services/loader.service';

@Component({
  selector: 'app-e2',
  templateUrl: './e2.component.html',
  styleUrls: ['./e2.component.css']
})
export class E2Component implements OnInit {
  user_sgid: string;
  countries = [];

  constructor(private ls:LoaderService,private route:ActivatedRoute,private _api:ApiService) { }

  ngOnInit(): void {
    this.ls.showLoader('Wait while loading country list');
    this.route.paramMap.subscribe( paramMap => {
      this.user_sgid = paramMap.get('user_sgid');
      this._api.countries$(this.user_sgid).subscribe(res=> {
       this.countries = res;
       this.ls.hideLoader();
      })
    })

  }

}
