import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-newactionplan',
  templateUrl: './newactionplan.component.html',
  styleUrls: ['./newactionplan.component.css']
})
export class NewactionplanComponent implements OnInit {
  user_sgid: string = this.navParams.get('sgid');
  constructor(public navParams: NavParams,private _api:ApiService,private md:ModalController,public formBuilder: FormBuilder) { }
  myForm: FormGroup;
  submitted = false;
  ngOnInit(): void {
    this.myForm = this.formBuilder.group({
      action: ['', [Validators.required, Validators.minLength(3)]],
      ladate: ['', [Validators.required]],
      validated: []
    })
  }

  onSubmit() {
    this.submitted = true;
    if (!this.myForm.valid) {
      console.log('All fields are required.')
      return false;
    } else {
      this._api.actionplan$(this.user_sgid,this.myForm.value).subscribe(res=> {
        console.log('save action plan',res);
        this.closeModal();
      })
      console.log(this.myForm.value)
    }
  }

  closeModal () {
    this.md.dismiss();
  }

}
