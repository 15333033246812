import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import json from 'highlight.js/lib/languages/json';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ErrorComponent } from './pages/error/error.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoadingComponent } from './components/loading/loading.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
import { IonicModule } from '@ionic/angular';
import { ResourcesComponent } from './pages/resources/resources.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AddsalesComponent } from './components/addsales/addsales.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ProgressBarModule} from "angular-progress-bar";
import { NgChartsModule } from 'ng2-charts';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SalesComponent } from './components/sales/sales.component';
import { AdminComponent } from './pages/admin/admin.component'
import {RadarComponent} from './components/radar/radar.component';
import { StepchartComponent } from './components/stepchart/stepchart.component';
import { AddComponent } from './pages/add/add.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {SharedModule} from './modules/shared/shared.module'
import {MatDialogModule} from '@angular/material/dialog';
import {LoaderService} from './services/loader.service';
import { NewcoachingsessionComponent } from './newcoachingsession/newcoachingsession.component';
import { NewpriorityComponent } from './newpriority/newpriority.component';
import { E2Component } from './e2/e2.component';
import { E2detailComponent } from './e2detail/e2detail.component';
import { NewactionplanComponent } from './newactionplan/newactionplan.component';
import { UsersComponent } from './users/users.component';
import { AppmanagerComponent } from './components/appmanager/appmanager.component';
import { AddmanagerComponent } from './components/addmanager/addmanager.component';
import { ViewsessionComponent } from './viewsession/viewsession.component';
import { NTranslateModule } from './services/translate/translate.module';
import { InternationalizationComponent } from './components/internationalization/internationalization.component';
import { ChangepasswordComponent } from './components/changepassword/changepassword.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    NavBarComponent,
    FooterComponent,
    LoadingComponent,
    ErrorComponent,
    ResourcesComponent,
    DashboardComponent,
    AddsalesComponent,
    SalesComponent,
    AdminComponent,
    AddComponent,
    NewcoachingsessionComponent,
    NewpriorityComponent,
    E2Component,
    E2detailComponent,
    NewactionplanComponent,
    UsersComponent,
    AppmanagerComponent,
    AddmanagerComponent,
    ViewsessionComponent,
    InternationalizationComponent,
    ChangepasswordComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatDialogModule,
    DragDropModule,
    HttpClientModule,
    NTranslateModule,
    ProgressBarModule,
    HighlightModule,
    FontAwesomeModule,
    IonicModule.forRoot(),

    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        ...env.httpInterceptor,
      },
    }),

  ],
  providers: [
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },

    {
      provide: Window,
      useValue: window,
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          json: () => import('highlight.js/lib/languages/json'),
        },
      },
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
