<ion-content>
    <ion-list>
        <ion-item *ngFor="let country of countries" [routerLink]="['/e2detail',country.originCC]">
            <ion-label>
                <h4>{{country.originCC}}</h4>
                <p><ion-badge>{{country.totalSalesmen}}</ion-badge> Sales people</p>
            </ion-label>
        </ion-item>
    </ion-list>
</ion-content>
