

<ion-toolbar color="secondary">
    <ion-buttons slot="end">
        <button (click)="closeModal()">
          <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
        </button>
    </ion-buttons>
<ion-title style="text-transform:uppercase;">New action plan</ion-title>
  </ion-toolbar>

  <ion-content>
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()" novalidate>
        <ion-item lines="full">
          <ion-label position="floating">New action</ion-label>
          <ion-textarea formControlName="action"></ion-textarea>
        </ion-item>
        <ion-item>
            <ion-label position="floating">Validate</ion-label>
            <ion-checkbox formControlName="validated" color="primary"></ion-checkbox>
        </ion-item>
        <ion-item>
            <ion-label position="floating">Due date</ion-label>
            <ion-datetime presentation="day-month-year" formControlName="ladate"></ion-datetime>
        </ion-item>
        <ion-row>
          <ion-col>
            <ion-button type="submit" color="danger" expand="block">Submit</ion-button>
          </ion-col>
        </ion-row>
      </form>
  </ion-content>