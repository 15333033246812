
  <ion-toolbar color="primary">
    <ion-title>Salescoaching-app</ion-title>

    <!-- Hamburger -->
    <ion-buttons slot="start">
      <ion-menu-button autoHide="false"></ion-menu-button>
    </ion-buttons>

  </ion-toolbar>
