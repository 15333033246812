import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import {UtilsService} from '../../services/utils.service';
@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})
export class SalesComponent implements OnInit {
  @Input() sales:any;

  constructor(public utils_service:UtilsService) { }

  ngOnInit(): void {
  }



}
