
<ion-segment color="primary" [(ngModel)]="type_ressource" >
    <ion-segment-button value="coaching" >
        <ion-label>Coaching resources</ion-label>
        <ion-icon name="person-outline"></ion-icon>
    </ion-segment-button>
    <ion-segment-button value="skills">
        <ion-label>Skill resources</ion-label>
        <ion-icon name="bulb-outline"></ion-icon>
    </ion-segment-button>
</ion-segment>
<ion-content  class="ion-padding">
    <div [ngSwitch]="type_ressource">
        <div *ngSwitchCase="'coaching'">
            <ion-list *ngIf="resources" [@listAnimationX]="resources.slice(0,2)">
                <ion-item button (click)="downloadPdf(resource)" *ngFor="let resource of resources.slice(0,2)">
                    <ion-label>
                        <h4 *ngIf="resource.name_trad == '' || !resource.name_trad">{{resource.name}}</h4>
                        <h4 *ngIf="resource.name_trad">{{resource.name_trad}}</h4>
                    </ion-label>
                </ion-item>
            </ion-list>
        </div>

        <div *ngSwitchCase="'skills'">
            <ion-list *ngIf="resources" [@listAnimationX]="resources.slice(2)">
                <ion-item button (click)="downloadPdf(resource)" *ngFor="let resource of resources.slice(2)">
                    <ion-label>
                        <h4 *ngIf="resource.name_trad == '' || !resource.name_trad">{{resource.name}}</h4>
                        <h4 *ngIf="resource.name_trad">{{resource.name_trad}}</h4>
                    </ion-label>
                </ion-item>
            </ion-list>
        </div>

    </div>
</ion-content>

