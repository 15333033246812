import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {
  type_add:string;
  data;

  item;
  selected_family;

  first_prio = [];
  second_prio = [];
  third_prio = [];
  constructor(private modalController:ModalController, private navParams: NavParams) { }

  ngOnInit(): void {

    console.table(this.navParams);
    this.type_add = this.navParams.data.type_add;
    this.data = this.navParams.data.data;
    if(this.type_add =='priorities') {
      this.selected_family = 0+"";
    }
  }

  async closeModal() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log('drop',event.container.data);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }



}
