import { Component, OnInit } from '@angular/core';
import ENJson from '../../../assets/i18n/en.json';
@Component({
  selector: 'app-internationalization',
  templateUrl: './internationalization.component.html',
  styleUrls: ['./internationalization.component.css']
})
export class InternationalizationComponent implements OnInit {

  constructor() {
    console.log(ENJson);
   }

  ngOnInit(): void {
  }

}
