<ion-content>
  <ion-list>
    <ion-item button (click)="getData()">
      <ion-icon name="download-outline" slot="start"></ion-icon>
      <ion-label>
        Export data to Excel
      </ion-label>
    </ion-item>

      <!-- <ion-item button [routerLink]="'translations'">
      <ion-icon name="earth-outline" slot="start"></ion-icon>
      <ion-label>
        Internationalization
      </ion-label>
    </ion-item> -->
  </ion-list>
    <!-- <ion-slides #slider pager="false" [options]="utils.slideOpts" >
        <ion-slide >
            <ion-card class="ion-text-start" button >
                <img src="https://f.hellowork.com/blogdumoderateur/2016/04/Microsoft-Excel-Logo-612x323.png">
                <ion-card-header>
                  <ion-card-subtitle>Data</ion-card-subtitle>
                  <ion-card-title>Export data to Excel</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                </ion-card-content>
              </ion-card>
        </ion-slide>
        <ion-slide >
            <ion-card class="ion-text-start" button >
                <img src="https://f.hellowork.com/blogdumoderateur/2016/04/Microsoft-Excel-Logo-612x323.png">
                <ion-card-header>
                  <ion-card-subtitle>Users</ion-card-subtitle>
                  <ion-card-title>Manage users</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                </ion-card-content>
              </ion-card>
        </ion-slide>
        <ion-slide >
            <ion-card class="ion-text-start" button >
                <img src="https://f.hellowork.com/blogdumoderateur/2016/04/Microsoft-Excel-Logo-612x323.png">
                <ion-card-header>
                  <ion-card-subtitle>Internationalization</ion-card-subtitle>
                  <ion-card-title>Manage internationalization</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                </ion-card-content>
              </ion-card>
        </ion-slide>
      </ion-slides> -->
</ion-content>
