import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ChangepasswordComponent } from '../components/changepassword/changepassword.component';
import { ApiService } from '../services/api.service';
import { LoaderService } from '../services/loader.service';

@Component({
  selector: 'app-e2detail',
  templateUrl: './e2detail.component.html',
  styleUrls: ['./e2detail.component.css']
})
export class E2detailComponent implements OnInit {
  country_id: string;
  country: any;

  constructor(public dialog: MatDialog,private route:ActivatedRoute,private ls:LoaderService,private _api:ApiService) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe( paramMap => {
      this.country_id = paramMap.get('country_id');
      this.ls.showLoader('Wait while loading country detail');
      this._api.country$(this.country_id).subscribe(res=> {
        this.ls.hideLoader();
       this.country = res;

      })
    })
  }

  openDialogChangePassword(user): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-component";
    dialogConfig.height = "350px";
    dialogConfig.width = "600px";
    dialogConfig.maxWidth= '100vw',
    dialogConfig.maxHeight= '100vh',
    dialogConfig.height= '100%',
    dialogConfig.width= '100%',
    dialogConfig.panelClass = 'bg-white';
    dialogConfig.data = {
      user:user
    }
    const modalDialog = this.dialog.open(ChangepasswordComponent, dialogConfig);
    // alert('openDialogChangePassword');
    // const dialogRef = this.dialog.open(ChangepasswordComponent, {
    //   width: '100%',
    // height: '100%',
    //   data: {'id_user':id_user},
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');

    // });
  }

}
