import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  constructor(public utils:UtilsService,private apis:ApiService) { }

  ngOnInit(): void {
  }


  getData() {
    this.apis.adminexport$().subscribe(res=> {
      console.log('resexport',res);
      saveAs(res,'export.xls');
    })
  }

}
