<ion-app>
  <ion-menu contentId="main-content" side="start" *ngIf="(auth.isAuthenticated$ | async) === true">


    <ion-content>
      <ion-item *ngIf="auth.user$ | async as user">
        <ion-avatar slot="start">
          <img
          alt="Profile picture"
          class="nav-user-profile d-inline-block rounded-circle mr-3"
          style="width: 75px;"
          [src]="user.picture"
        />
        </ion-avatar>
        <ion-label>
         {{user.name}}
        </ion-label>
      </ion-item>
      <ion-list *ngIf="auth.user$ | async as user">
        <ion-menu-toggle auto-hide="false" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" >
          <ion-item button routerLink="/home" >
            <ion-icon name="people-circle-outline" slot="start"></ion-icon>
            <ion-label *ngIf='user[urlEnv + "/grade_user"] == 1'>{{'team_overview' | translate}} </ion-label>
            <ion-label *ngIf='user[urlEnv + "/grade_user"] != 1'>{{'my_dashboard' | translate}}</ion-label>
          </ion-item>
          <!-- <ion-item button [routerLink]="'/dashboard'">
            <ion-icon name="stats-chart-outline" slot="start"></ion-icon>
            <ion-label>Dashboard</ion-label>
          </ion-item> -->
          <ion-item button [routerLink]="'/resources'">
            <ion-icon name="document-outline" slot="start"></ion-icon>
            <ion-label>{{ 'resources' | translate}}</ion-label>
          </ion-item>
          <!-- <ion-item button [routerLink]="'/profile'">
            <ion-icon name="person-outline" slot="start"></ion-icon>
            <ion-label>My profile</ion-label>
          </ion-item> -->
          <ion-item button [routerLink]="'/admin'" *ngIf='user[urlEnv +"/grade_user"] == 1'>
            <ion-icon name="settings-outline" slot="start"></ion-icon>
            <ion-label>{{'administration' | translate}}</ion-label>
          </ion-item>
          <ion-item button (click)="logout()">
            <ion-icon name="log-out-outline" slot="end"></ion-icon>
            <ion-label>{{'logout' | translate}}</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>

  <div class="ion-page" id="main-content">

      <ion-toolbar color="secondary">
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
<ion-title style="text-transform:uppercase;">Salescoaching-app <ion-badge style="vertical-align:middle;" *ngIf="!isProd">V 0.0.1 PREPROD</ion-badge></ion-title>
      </ion-toolbar>

    <ion-content class="ion-padding">
        <ion-router-outlet id="main"></ion-router-outlet>
    </ion-content>
  </div>
</ion-app>