import { CdkDragDrop, copyArrayItem, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-newpriority',
  templateUrl: './newpriority.component.html',
  styleUrls: ['./newpriority.component.css']
})
export class NewpriorityComponent implements OnInit {
  priorities: any;
  prios= [];
  displaySkills = false;
  selected_family = 0;
  questions = [];
  first_prio= [];
   user_sgid: string = this.navParams.get('sgid');
  data: any;
  user: any;
  constructor(public navParams: NavParams,private api_service:ApiService,private md:ModalController) {
    this.api_service.dashboard$(this.user_sgid,-1).subscribe(res=> {
      this.priorities = res[2];
      this.data = res[1];
      this.user = res[0];
      console.log('this.priorities',this.priorities);
      this.priorities.forEach(element =>{
        this.first_prio.push(element);
    });
    });

   }

  ngOnInit() {

  }

  drop(event: CdkDragDrop<string[]>) {
    console.log('drop',event.container.data);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      //on checke que l'element nexiste pas deja dans le tableau TODO
      copyArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );

       //On verifie que array destination est vide
       if(event.container.data.length > 3) {
        this.first_prio =  this.first_prio.slice(0, -1);
            }

    }


  }

  closeModal () {
    this.md.dismiss();
  }

  setPriorities() {
    this.api_service.setpriorities$(this.user_sgid, this.first_prio).subscribe(res=> {
      console.log('set prios',res);
      this.md.dismiss();
    });
  }

}
