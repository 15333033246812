

<ion-content class="ion-padding" *ngIf="grade == 1">
  <h2 class="boldSf">Team Overview</h2>
  <ion-list *ngIf="!isLoading">
    <ion-list-header>
    <h3>Distribution sales</h3>
    </ion-list-header>
    <ion-item *ngIf="distributionSales && distributionSales.length === 0">
      <ion-label>
        <h5>No distribution sales yet.</h5>
      </ion-label>
    </ion-item>
    <ion-slides #slider pager="false" [options]="utils.slideOpts"  *ngIf="distributionSales.length > 0">
      <ion-slide *ngFor="let distribution_sales of distributionSales">
        <app-sales  [sales]="distribution_sales"></app-sales>
      </ion-slide>
    </ion-slides>
  <ion-list-header>
   <h3>Project sales</h3>
  </ion-list-header>
  <ion-item *ngIf="projectSales && projectSales.length === 0">
    <ion-label>
      <h5>No project sales yet.</h5>
    </ion-label>
  </ion-item>
  <ion-slides #slider pager="false" [options]="utils.slideOpts"  *ngIf="projectSales.length > 0">
    <ion-slide *ngFor="let project_sales of projectSales">
      <app-sales [sales]="project_sales"></app-sales>
    </ion-slide>
  </ion-slides>
  <ion-list-header>
   <h3>Specification sales</h3>
  </ion-list-header>
  <ion-item *ngIf="specificationSales && specificationSales.length === 0">
    <ion-label>
      <h5>No specification sales yet.</h5>
    </ion-label>
  </ion-item>
  <ion-slides #slider pager="false" [options]="utils.slideOpts"  >
    <ion-slide *ngFor="let specification_sales of specificationSales">
      <app-sales [sales]="specification_sales"></app-sales>
    </ion-slide>
  </ion-slides>
</ion-list>
</ion-content>
<ion-fab  vertical="bottom" horizontal="end" *ngIf="grade == 1">
  <ion-fab-button (click)="presentModalAddSales()" color="secondary">
    <ion-icon name="person-add-outline"></ion-icon>
  </ion-fab-button>
</ion-fab>


<ion-content class="ion-padding" *ngIf="grade == 3">
  <ion-list *ngIf="!isLoading">
    <ion-list-header>
      <h3>Sales Manager</h3>
      </ion-list-header>
      <ion-item *ngIf="managersSales && managersSales.length === 0">
        <ion-label>
          <h5>No sales manager yet.</h5>
        </ion-label>
      </ion-item>
      <ion-slides #slider pager="false" [options]="utils.slideOpts"  *ngIf="managersSales.length > 0">
        <ion-slide *ngFor="let manager of managersSales">
          <app-appmanager [manager]="manager"></app-appmanager>

        </ion-slide>
      </ion-slides>
  </ion-list>

</ion-content>
<ion-fab  vertical="bottom" horizontal="end" *ngIf="grade == 3">
  <ion-fab-button (click)="presentModalAddManager()" color="secondary">
    <ion-icon name="person-add-outline"></ion-icon>
  </ion-fab-button>
</ion-fab>

