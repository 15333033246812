import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { ModalController } from '@ionic/angular';
import { ApiService } from '../../services/api.service';
import { environment as env } from '../../../environments/environment';
@Component({
  selector: 'app-addmanager',
  templateUrl: './addmanager.component.html',
  styleUrls: ['./addmanager.component.css']
})
export class AddmanagerComponent implements OnInit {

  priorities: any;
  data: any;
  ionicForm : FormGroup;
  urlEnv = env.envKey;
  segmentModel = 1;
  constructor(private router: Router,public formBuilder: FormBuilder,private auth:AuthService,private api_service:ApiService,private modalController:ModalController) {
    this.ionicForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      firstname: ['', [Validators.required]],
      mail: ['', [Validators.required]],
      country: ['', [Validators.required]],
      sgid: ['', [Validators.required]]
    })



   }

  ngOnInit(): void {
    this.auth.user$.subscribe(res => {
      this.api_service.initializeSession$(res[this.urlEnv + '/id_user']).subscribe(res=> {
        console.log('initializeSession',res);
       this.data = res;
      });

  })

  }



  submitForm() {

    console.log(this.ionicForm.value);

      console.log(this.data.skills);
      let objuser = this.ionicForm.value;
      this.api_service.setmanager$(objuser).subscribe(res=> {
        console.log('setfa',res);
        this.router.navigate(['/home']).then(() => {
          window.location.reload();
        });
      })


  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
