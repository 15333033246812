import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import config from '../../../auth_config.json';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  commercials$(): Observable<any> {
    return this.http.get(`${config.apiUri}/commercials`);
  }

  directorinfo$(): Observable<any> {
    return this.http.get(`${config.apiUri}/directorinfo`);
  }

  changeUserPassword(password): Observable<any> {
    return this.http.post(`${config.apiUri}/changepassword`,{password:password});
  }


  resources$(id_country): Observable<any> {
    return this.http.get(`${config.apiUri}/resources/${id_country}`);
  }

  lastSessionNotes$(sgid,family_id): Observable<any> {
    return this.http.get(`${config.apiUri}/notes/lastsession/`+ sgid);
  }

  getSKillsNotes$(sgid,family_id,session_id): Observable<any> {
    return this.http.get(`${config.apiUri}/notes/stepchart/`+ sgid+`/`+family_id +`/`+session_id);
  }

  sparkLines$(sgid,family_id,skill_id): Observable<any> {
    return this.http.get(`${config.apiUri}/sparklines/`+ sgid +`/`+ family_id +`/`+ skill_id);
  }

  notes$(sgid,family_id): Observable<any> {
    return this.http.get(`${config.apiUri}/notes/`+ sgid);
  }

  dashboard$(sgid,family_id): Observable<any> {
    return this.http.get(`${config.apiUri}/dashboard/`+ sgid);
  }

  initializeSession$(id_country): Observable<any> {
    return this.http.get(`${config.apiUri}/initializesession/`+ id_country);
  }

  skills$(sgid): Observable<any> {
    return this.http.get(`${config.apiUri}/skills/`+ sgid);
  }

  sessions$(sgid): Observable<any> {
    return this.http.get(`${config.apiUri}/sessions/`+ sgid);
  }

  subskillQuestion$(sgid,subskill_id): Observable<any> {
    return this.http.get(`${config.apiUri}/subskillquestion/`+ sgid + `/`+ subskill_id);
  }



  actionplans$(sgid): Observable<any> {
    return this.http.get(`${config.apiUri}/actionplans/`+ sgid);
  }

  actionplan$(sgid,actionplan): Observable<any> {
    return this.http.put(`${config.apiUri}/actionplan/`+ sgid, JSON.stringify(actionplan));
  }

  adminexport$(): Observable<any> {
    return this.http.get(`${config.apiUri}/export`, {responseType: 'blob'});
  }

  document$(sgid,id_doc): Observable<any> {
    return this.http.get(`${config.apiUri}/document/`+ sgid +`/`+ id_doc, {responseType: 'blob'});
  }
  actionplan_validate$(sgid,actionplan): Observable<any> {
    return this.http.patch(`${config.apiUri}/actionplan/`+ sgid, JSON.stringify(actionplan));
  }

  countries$(sgid): Observable<any> {
    return this.http.get(`${config.apiUri}/countries/`+ sgid);
  }

  country$(id_country): Observable<any> {
    return this.http.get(`${config.apiUri}/country/`+ id_country);
  }

  setpriorities$(sgid,priorities): Observable<any> {
    return this.http.put(`${config.apiUri}/priorities/`+ sgid,JSON.stringify(priorities));
  }

  setfa$(user): Observable<any> {
    return this.http.put(`${config.apiUri}/user`,JSON.stringify(user));
  }

  setmanager$(user): Observable<any> {
    return this.http.put(`${config.apiUri}/manager`,JSON.stringify(user));
  }

  archive$(sgid): Observable<any> {
    return this.http.get(`${config.apiUri}/archive/` + sgid);
  }

  setcoachingsession$(sgid,data): Observable<any> {
    return this.http.put(`${config.apiUri}/coachingsession/`+ sgid,JSON.stringify(data));
  }


}
