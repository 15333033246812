

<ion-toolbar color="secondary">
    <ion-buttons slot="end">
        <button (click)="dismiss()">
            <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
        </button>
    </ion-buttons>
    <ion-title style="text-transform:uppercase;">Session detail</ion-title>
</ion-toolbar>
<ion-content *ngIf="questions">
    <ion-item>
        <ion-label>
           <h3>Session Date: {{questions[0].session_date | date:'mediumDate'}}</h3>
           <p>Skill Name: {{questions[0].skill_trad}}</p>
        </ion-label>
    </ion-item>
    <ion-list >
        <ion-item>
          <ion-grid>
            <ion-row >
              <ion-col size="6">

              </ion-col>
              <ion-col size="2">
                Occasionally
              </ion-col>
              <ion-col size="2">
              Regularly
              </ion-col>
              <ion-col size="2">
Always
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
          <ion-item *ngFor="let question of questions">
            <ion-grid >

            <ion-radio-group  [(ngModel)]="question.note" >
              <ion-row >
                <ion-col size="6">
                {{question.sub_skill_name}}
                </ion-col>

                <ion-col size="2">
                  <ion-radio class="readonlyCheck"  [value]="0"></ion-radio>
                </ion-col>
                <ion-col size="2">
                  <ion-radio class="readonlyCheck"  [value]="1"></ion-radio>
                </ion-col>
                <ion-col size="2">
                <ion-radio class="readonlyCheck"  [value]="2"></ion-radio>
                </ion-col>
              </ion-row>
            </ion-radio-group>
            </ion-grid>
          </ion-item>
      </ion-list>
</ion-content>