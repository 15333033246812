import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-appmanager',
  templateUrl: './appmanager.component.html',
  styleUrls: ['./appmanager.component.css']
})
export class AppmanagerComponent implements OnInit {
  @Input() manager;
  constructor(public utils_service:UtilsService) { }

  ngOnInit(): void {
  }

}
