

<ion-toolbar color="secondary">
    <ion-buttons slot="end">
        <button (click)="closeModal()">
          <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
        </button>
    </ion-buttons>
<ion-title style="text-transform:uppercase;">New coaching session</ion-title>
  </ion-toolbar>

  <ion-content>
      <ion-item>
      <ion-label class="ion-text-wrap">
        Select each priority skill to assess : (assess all selected skills before saving session)
      </ion-label>

    </ion-item>
    <ion-badge *ngFor="let prio of prios" (click)="getQuestions(prio.skill_id);displaySkills = false;" class="badge" color="success">
        {{prio.skill_name}}
                </ion-badge>
                <ion-badge (click)="questions = [];showSkills();" class="badge">
                      Add other skills ?
                </ion-badge>

                <!-- <ion-list *ngIf="displaySkills">
                  <ion-item *ngFor="let skill of data;">
               <ion-item *ngFor="let skill of data;" (click)="getQuestions(skill.family_skill_id);displaySkills = false;">
                    <ion-label>
                      {{skill.family_skill_name}}
                    </ion-label>
                  </ion-item>
                </ion-list> -->

                <ion-segment *ngIf="displaySkills"  scrollable="true" mode="ios"  swipe-gesture="false" style="border-radius:0px !important">

                  <ion-grid>
                    <ion-row>
                      <ion-segment-button *ngFor="let skill of data" value="{{skill.family_skill_id}}" (click)="selectData(skill)">
                        <ion-label class="bl">{{skill?.family_skill_name}}</ion-label>
                      </ion-segment-button>
                    </ion-row>
                    <ion-row>
                      <ion-badge  class="badge" *ngFor="let available_subskill of available_subskills" (click)="getQuestions(available_subskill.skill_id);">
                        {{available_subskill.skill_name}}
                  </ion-badge>
                    </ion-row>
                  </ion-grid>
                  </ion-segment>

                <ion-list *ngIf="questions.length > 0">
                  <ion-item>
                    <ion-grid>
                      <ion-row >
                        <ion-col size="6">

                        </ion-col>
                        <ion-col size="2">
                          Occasionally
                        </ion-col>
                        <ion-col size="2">
                        Regularly
                        </ion-col>
                        <ion-col size="2">
        Always
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-item>
                    <ion-item *ngFor="let question of questions">
                      <ion-grid >

                      <ion-radio-group  [(ngModel)]="question.note">
                        <ion-row >
                          <ion-col size="6">
                          {{question.sub_skill_name}}
                          </ion-col>
                          <ion-col size="2">
                            <ion-radio (click)="questionChanged(question,0)" [value]="0"></ion-radio>
                          </ion-col>
                          <ion-col size="2">
                            <ion-radio (click)="questionChanged(question,1)"  [value]="1"></ion-radio>
                          </ion-col>
                          <ion-col size="2">
                          <ion-radio (click)="questionChanged(question,2)"  [value]="2"></ion-radio>
                          </ion-col>
                        </ion-row>
                      </ion-radio-group>
                      </ion-grid>
                    </ion-item>
                </ion-list>

<ion-grid style="text-align:center;">
  <ion-row>


    <ion-col size="6">
    Positive aspects
    <ion-textarea style="background-color:white;border-radius:5px;" [(ngModel)]="positive_aspects" placeholder="Add information here..."></ion-textarea>
    </ion-col>

    <ion-col size="6">
      Areas for improvements
      <ion-textarea style="background-color:white;border-radius:5px;"  [(ngModel)]="area_improvements" placeholder="Add information here..."></ion-textarea>
      </ion-col>
  </ion-row>


</ion-grid>


  </ion-content>

  <ion-footer class="ion-no-border">



    <ion-row>
      <ion-col>


          <ion-button type="submit" (click)="validateCoachingSession()"
          expand="full"  color="secondary" >
           Validate session
          </ion-button>



      </ion-col>
    </ion-row>

  </ion-footer>