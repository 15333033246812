
<ion-card class="ion-text-start" button  >
    <img src="https://placem.at/places?overlay_color=eb7a85&w=600&h=290">
    <ion-card-header>
      <ion-card-subtitle >{{manager.role_name}}</ion-card-subtitle>
      <ion-card-title>{{manager.first_name}} {{manager.last_name}}</ion-card-title>
      <p>{{manager.totalSalesmen}} Sales people</p>
    </ion-card-header>
    <ion-card-content>
      <progress-bar [progress]="utils_service.arrondi(manager.pourcentage)" [color-degraded]="{'0': '#00cbcb',  '15': '#f9c3d3', '25': '#fd8c8e', '50': '#B7B722','74': '#3CBC3C'}">
      </progress-bar>
    </ion-card-content>
  </ion-card>
