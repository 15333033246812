import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-newcoachingsession',
  templateUrl: './newcoachingsession.component.html',
  styleUrls: ['./newcoachingsession.component.css']
})
export class NewcoachingsessionComponent implements OnInit {
  priorities: any;
  prios= [];
  available_subskills = [];
  positive_aspects="";
  area_improvements="";
  displaySkills = false;
  sexe = 'homme';
  questions = [];
  user_sgid: string = this.navParams.get('sgid');
  data: any;
  nouveauRatingsAEnvoyer = [];
  constructor(public navParams: NavParams,private api_service:ApiService,private md:ModalController) {
    this.api_service.dashboard$(this.user_sgid,-1).subscribe(res=> {
      this.priorities = res[2];
      this.data = res[1];
      console.log('this.priorities',this.priorities);
      this.priorities.forEach(element =>{
            this.prios.push(element);
        });
    });

   }

  ngOnInit() {

  }

  validateCoachingSession() {
    let arrPost= {data: []};
    arrPost.data.push({positive:this.positive_aspects, negative:this.area_improvements});
    arrPost.data.push(this.nouveauRatingsAEnvoyer);
    this.api_service.setcoachingsession$(this.user_sgid,arrPost.data).subscribe(res => {
      console.log('setcoachingsession');
      console.log('set prios',res);
      this.md.dismiss();
    })

    console.log(arrPost);
  }

  questionChanged(question,note) {

    let obj = {new_rating:note,subskill_id:question.sub_skill_id};
    if (this.nouveauRatingsAEnvoyer.indexOf(obj) == -1) {
      let objettrouve = false;
      const filteredResult = this.nouveauRatingsAEnvoyer.find((e) => e.subskill_id == obj.subskill_id);
      for (const i in this.nouveauRatingsAEnvoyer) {
        if (this.nouveauRatingsAEnvoyer[i].subskill_id == obj.subskill_id) {
          this.nouveauRatingsAEnvoyer[i].new_rating = obj.new_rating;
          objettrouve= true;
        }
      }

      if (!objettrouve) {
         //On checke que l'on a pas deja un object avec ce subskill
      this.nouveauRatingsAEnvoyer.push(obj);
      }

    }

  }

  showSkills() {
    this.displaySkills = true;
  }

  selectData(skill) {
    console.log(skill);
    this.available_subskills = skill.subskills;
  }

  getQuestions(skill_id) {
    this.api_service.subskillQuestion$(this.user_sgid,skill_id).subscribe(res=> {
      console.log('subskillQuestion',res);
      this.questions = res;
    });
  }

  closeModal () {
    this.md.dismiss();
  }

}
