import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Auth0 Angular SDK Sample';
  urlEnv = env.envKey;
  isProd = env.production;
  constructor(public auth:AuthService, @Inject(DOCUMENT) private doc: Document) {
    console.log('envitonement',env.production);
  }

  logout() {
    this.auth.logout({ returnTo: this.doc.location.origin });
  }
}
