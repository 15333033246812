import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { LoaderService } from 'src/app/services/loader.service';
import { UtilsService } from 'src/app/services/utils.service';
import {NewCoachingService} from '../../services/new-coaching.service';
import {AddComponent} from '../add/add.component';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  user_sgid;
  lastSessionNotes;
  segmentModel = 'all';
  segmentModelMain= 'prioritiessegment';
  datatest = [
       { data: [ 4, 3, 3, 3, 3, 3, 3 ], label: 'Expected Level' },
       { data: [ 2, 1, 3, 2, 3, 2, 3 ], label: 'Initial assesment' }
     ];
  labels = [ 'Project Selling', 'Health and Safety', 'Technical Skills', 'Administration', 'Territory & Biz Mgmt', 'Account management', 'Sales skills' ];
  data;
  sessions;
  user;
  skills_arr= [];
  priorities: any;
  public slideOpts = {
    initialSlide: 1,
    slidesPerView: 2.2,
    speed: 400,
    breakpoints: {
      // when window width is <= 320px
      320: {
        slidesPerView: 2,
        spaceBetween: 5
      },
      480: {
        slidesPerView: 2.2,
        spaceBetween: 10
      },
      640: {
        slidesPerView: 3.2,
        spaceBetween: 30
      },
      1200: {
        slidesPerView: 4.2,
        spaceBetween: 30
      }
    }
  };
  constructor(private _loader:LoaderService,private modalController:ModalController,public utils:UtilsService,public auth:AuthService,private coaching_service:NewCoachingService,private api_service:ApiService,private route:ActivatedRoute) {
    this.route.paramMap.subscribe( paramMap => {
      this.user_sgid = paramMap.get('user_sgid');
    })
   }

   async openModal(type_add) {
    const modal = await this.modalController.create({
      component: AddComponent,
      componentProps: {
        "type_add": type_add,
        "data": this.data
      }
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        //this.dataReturned = dataReturned.data;
        //alert('Modal Sent Data :'+ dataReturned);
      }
    });

    return await modal.present();
  }


   ngOnInit() {
    this._loader.showLoader('Wait while loading');
    this.api_service.dashboard$(this.user_sgid,-1).subscribe(res=> {
      console.log('dashboard',res);
      this.user = res[0];
      this.data = res[1];
      this.priorities = res[2];
      this.api_service.sessions$(this.user_sgid).subscribe(res=> {
        console.log('sessions',res);
        this.sessions = res;
        this._loader.hideLoader();
      });

    });

  }

}
