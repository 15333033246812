


<ion-toolbar color="secondary">
    <ion-buttons slot="end">
        <button (click)="dismiss()">
            <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
        </button>
    </ion-buttons>
    <ion-title style="text-transform:uppercase;">Add a salesperson profile</ion-title>
</ion-toolbar>
<ion-content>


    <form [formGroup]="ionicForm"  novalidate>
        <ion-radio-group lines="full" formControlName="role">
            <ion-list-header>
              <ion-label>Choose a sales team *</ion-label>
            </ion-list-header>
            <ion-item>
              <ion-label>Distribution Sales</ion-label>
              <ion-radio slot="start" value="1" checked></ion-radio>
            </ion-item>
            <ion-item>
              <ion-label>Project Sales</ion-label>
              <ion-radio slot="start" value="2"></ion-radio>
            </ion-item>
            <ion-item>
                <ion-label>Specification Sales</ion-label>
                <ion-radio slot="start" value="3"></ion-radio>
              </ion-item>
          </ion-radio-group>
        <ion-item lines="full">
          <ion-label position="floating">Name</ion-label>
          <ion-input formControlName="name" type="text" required></ion-input>
        </ion-item>
        <ion-item lines="full">
          <ion-label position="floating">Firstname</ion-label>
          <ion-input formControlName="firstname" type="text" required></ion-input>
        </ion-item>
        <ion-item lines="full">
          <ion-label position="floating">Country</ion-label>
          <ion-input readonly  formControlName="country" ([ngModel])="data.country" [value]="data?.country"  type="text" required></ion-input>
        </ion-item>
        <ion-item lines="full">
          <ion-label position="floating">Mail Adress</ion-label>
          <ion-input type="text" formControlName="mail" required></ion-input>
        </ion-item>
        <ion-item lines="full">
            <ion-label position="floating">SGID</ion-label>
            <ion-input formControlName="sgid" type="text" required></ion-input>
          </ion-item>
        </form>
        <ion-segment [(ngModel)]="segmentModel"   scrollable="true" mode="ios"  swipe-gesture="false" style="border-radius:0px !important">

            <ion-grid *ngIf="data">
              <ion-row>
                <ion-segment-button *ngFor="let skill of data.skills" value="{{skill.family_skill_id}}">
                  <ion-label class="bl">{{skill?.family_skill_name}}</ion-label>
                </ion-segment-button>
              </ion-row>
              <ion-row>

              </ion-row>
            </ion-grid>
            </ion-segment>
            <div *ngIf="data">
            <div *ngFor="let skill of data.skills">
              <p [hidden]="segmentModel != skill.family_skill_id">
                <ion-list *ngIf="skill.subskills && skill.subskills.length > 0">
                  <ion-item>
                    <ion-grid>
                      <ion-row >
                        <ion-col size="6">

                        </ion-col>
                        <ion-col size="2">
                          Learner
                        </ion-col>
                        <ion-col size="2">
                        Developer
                        </ion-col>
                        <ion-col size="2">
        Master
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-item>
                    <ion-item *ngFor="let question of skill.subskills">
                      <ion-grid >
                      <ion-radio-group ([ngModel])="question.note">
                        <ion-row >
                          <ion-col size="6">
                          {{question.skill_name}}
                          </ion-col>
                          <ion-col size="2">
                            <ion-radio (click)="questionChanged(question,0)" [value]="0"></ion-radio>
                          </ion-col>
                          <ion-col size="2">
                            <ion-radio (click)="questionChanged(question,1)"  [value]="1"></ion-radio>
                          </ion-col>
                          <ion-col size="2">
                          <ion-radio (click)="questionChanged(question,2)"  [value]="2"></ion-radio>
                          </ion-col>
                        </ion-row>
                      </ion-radio-group>
                      </ion-grid>
                    </ion-item>
                </ion-list>

              </p>
            </div>
          </div>

        <ion-row>
          <ion-col>
            <ion-button (click)="submitForm()" [disabled]="ionicForm.invalid"  type="submit" color="danger" expand="block">Submit</ion-button>
          </ion-col>
        </ion-row>

</ion-content>
