
    <ion-item lines="none">
        <ion-avatar slot="start">
            <img src="https://placem.at/places?overlay_color=eb7a85&w=600&h=290">
        </ion-avatar>
      <ion-label class="ion-text-wrap" >
          <h3>{{user?.username}}</h3>
          <p>Role : {{user?.role_name}} Country : {{user?.country}}</p>
      </ion-label>
    </ion-item>
    <!-- <ion-segment [value]="segmentModelMain" scrollable="true" mode="ios" [(ngModel)]="segmentModelMain" swipe-gesture="false" style="border-radius:0px !important">
      <ion-segment-button value="prioritiessegment">
        <ion-label>Priorities</ion-label>
      </ion-segment-button>
      <ion-segment-button value="actionplan">
        <ion-label>Action plan</ion-label>
      </ion-segment-button>
      <ion-segment-button value="coachingsessions">
        <ion-label>Coaching Sessions</ion-label>
      </ion-segment-button>
      </ion-segment> -->
<!--
      <ion-button (click)="openModal('priorities')" *ngIf="segmentModelMain == 'prioritiessegment' && user?.role_id == 2" color="secondary">
        <ion-label>Add a priority</ion-label>
        <ion-icon name="add-circle-outline"></ion-icon>
        </ion-button> -->
    <!-- <ion-slides #slider pager="false" [options]="slideOpts" [hidden]="segmentModelMain != 'prioritiessegment'">
      <ion-slide *ngFor="let priority of priorities">
        <ion-card >
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h4 *ngIf="priority.skill_name_trad"> {{priority.skill_name_trad}}</h4>
              <h4 *ngIf="!priority.skill_name_trad && priority.skill_name"> {{priority.skill_name}}</h4>
            </ion-label>
          </ion-item>

        </ion-card>
      </ion-slide>
    </ion-slides> -->
    <!-- <ion-button (click)="openModal('actionplan')" *ngIf="segmentModelMain == 'actionplan' && user?.role_id == 2" color="secondary">
      <ion-label>Add an action plan</ion-label>
      <ion-icon name="add-circle-outline"></ion-icon>
      </ion-button>
  <ion-slides #slider pager="false" [options]="slideOpts" [hidden]="segmentModelMain != 'actionplan'">
    <ion-slide *ngFor="let priority of priorities">
      <ion-card >
        <ion-item>
          <ion-label class="ion-text-wrap">
            <h4 *ngIf="priority.skill_name_trad"> {{priority.skill_name_trad}}</h4>
            <h4 *ngIf="!priority.skill_name_trad && priority.skill_name"> {{priority.skill_name}}</h4>

          </ion-label>
        </ion-item>

      </ion-card>
    </ion-slide>
  </ion-slides> -->
    <!-- <ion-item *ngIf="priorities && segmentModelMain == 'prioritiessegment' && priorities.length == 0">
      <ion-label>
        No priorities
      </ion-label>

    </ion-item>
    <ion-button (click)="openModal('coachingsession')" *ngIf="segmentModelMain == 'coachingsessions' && user.role_id == 2" color="secondary">
      <ion-label>Add a coaching session</ion-label>
      <ion-icon name="add-circle-outline"></ion-icon>
      </ion-button>
    <ion-slides #slider pager="false" [options]="slideOpts" [hidden]="segmentModelMain != 'coachingsessions'">
      <ion-slide *ngFor="let session of sessions;let i = index;">
        <ion-card style="text-align:left" style="width:25vw;">
   <ion-card-header>
            <ion-card-subtitle>{{session.session_date}}</ion-card-subtitle>
            <ion-card-title>Session #{{i + 1}}</ion-card-title>
          </ion-card-header>
          <ion-grid>
            <ion-row>
              <ion-col>
                {{session.session_date | amDateFormat:'LL'}}<br>
                Evaluated skills: <br><ion-badge color="danger">66</ion-badge>
              </ion-col>

            </ion-row>

            <ion-col>
              <ion-row>
                <ion-col *ngIf="session.positive != ''"> <p class="elipse">{{session.positive}}</p></ion-col>
                <ion-col *ngIf="session.negative != ''"><p class="elipse">{{session.negative}}</p></ion-col>
              </ion-row>
            </ion-col>
          </ion-grid>





        </ion-card>
      </ion-slide>
    </ion-slides> -->
  <ion-segment [value]="segmentModel" scrollable="true" mode="ios" [(ngModel)]="segmentModel" swipe-gesture="false" style="border-radius:0px !important">

  <ion-grid>
    <ion-row>
      <ion-segment-button value="all">
        <ion-label class="bl">All family</ion-label>
      </ion-segment-button>
      <ion-segment-button *ngFor="let skill of data" value="{{skill.family_skill_id}}">
        <ion-label class="bl">{{skill?.family_skill_name}}</ion-label>
      </ion-segment-button>
    </ion-row>
  </ion-grid>
  </ion-segment>
<ion-content class="ion-padding" *ngIf="data">
    <ion-card *ngIf="segmentModel == 'all'">
        <ion-card-header>
            <ion-card-title>
                <h4>First, actual and expected skills' levels f</h4>
            </ion-card-title>
        </ion-card-header>
        <ion-grid >
          <ion-row>
            <ion-col sizeLg="4" sizeMd="4" sizeXs="12">
              <app-radar *ngIf="data" [typeGraphe]="'general'" [data]='data'></app-radar>
            </ion-col>
            <ion-col sizeLg="8" sizeMd="8" sizeXs="12">
kjlkjlkjlkjlk
            </ion-col>
          </ion-row>
        </ion-grid>

    </ion-card>

    <ng-model *ngFor="let skill of data">
      <ion-card *ngIf="segmentModel == skill.family_skill_id">
        <ion-grid>
          <ion-row>
            <ion-col sizeLg="4" sizeMd="4" sizeXs="12">
              <h5>{{skill?.family_skill_name}}</h5>
              <app-radar [typeGraphe]="'famille'" [data]='skill.subskills'></app-radar>
            </ion-col>
            <ion-col  sizeLg="8" sizeMd="8" sizeXs="12">
              <h5>Evolution family's skills</h5>
              <ion-list>
                <ion-item *ngFor="let subskill of skill.subskills;let i = index">
                  <ion-label>
                    <h5>{{subskill.skill_name}}</h5>
                  </ion-label>
                  <app-stepchart [data]='subskill' [color]='utils.arrayColorSkills[i]' style="height:100px;width:50%;"></app-stepchart>
                </ion-item>
              </ion-list>
            </ion-col>
          </ion-row>
        </ion-grid>
    </ion-card>
    </ng-model>
</ion-content>
