

<ion-toolbar color="secondary">
    <ion-buttons slot="end">
        <button (click)="closeModal()">
          <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
        </button>
    </ion-buttons>
<ion-title style="text-transform:uppercase;">New priorities</ion-title>
  </ion-toolbar>

  <ion-content>
      <!-- <ion-item>
      <ion-label class="ion-text-wrap">
        Edit skills priorities
      </ion-label>

    </ion-item> -->


    <div *ngIf="user">

        <ion-item>
            <ion-label>List of skills (Drag & drop skills on priorities)</ion-label>
            <ion-select [placeholder]="data[0].family_skill_name" [(ngModel)]="selected_family">
              <ion-select-option  *ngFor="let family of data;let i = index;" value="{{i}}">{{family.family_skill_name}}</ion-select-option>
            </ion-select>
          </ion-item>

          <div cdkDropListGroup  style="padding:10px;">
            <div class="example-container">
              <div
                cdkDropList
                [cdkDropListData]="data[selected_family].subskills"
                class="example-list"
                (cdkDropListDropped)="drop($event)"
                [@listAnimationX]="data[selected_family].subskills">
                <div class="example-available" *ngFor="let item of data[selected_family].subskills" cdkDrag>{{item.skill_name}}</div>
              </div>
            </div>

            <div class="example-container">
              <h5>Priorities</h5>
              <div
                cdkDropList
                [cdkDropListData]="first_prio"
                class="example-list"
                style="min-height:20px;"
                (cdkDropListDropped)="drop($event)">
                <div class="example-selected" *ngFor="let item of first_prio;let i = index;" cdkDrag>{{i + 1}} {{item.skill_name}}</div>
              </div>
            </div>

          </div>
    </div>


  </ion-content>

<ion-footer class="ion-no-border">



  <ion-row>
    <ion-col>


        <ion-button type="submit"
        expand="full"  color="secondary" (click)="setPriorities()">
         Save changes
        </ion-button>



    </ion-col>
  </ion-row>

</ion-footer>