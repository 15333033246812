import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { ListAnimationX, FadeDownAnimation, FadeUpAnimation } from 'src/animations/basic.animations';

import { environment as env } from '../../../environments/environment';
import { saveAs } from 'file-saver';
import { ApiService } from '../../services/api.service';
declare var require: any
const FileSaver = require('file-saver');
@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css'],
  animations: [
    ListAnimationX,
    FadeDownAnimation,
    FadeUpAnimation
  ]
})
export class ResourcesComponent implements OnInit {
  type_ressource;
  resources= [];
  sgid: any;
  envUrl = env.envKey;
  constructor(private auth:AuthService,private api_service:ApiService) {

  }

  ngOnInit(): void {
    this.auth.user$.subscribe(res => {
      console.log('user',res);
      this.sgid = res[this.envUrl + '/id_user'];
      this.type_ressource = 'coaching';
      this.api_service.resources$(this.sgid).subscribe(res=> {
        this.resources = res;
        console.log(res);
      })
  })
  }

  downloadPdf(pdfdoc) {
    console.log(pdfdoc);
    this.api_service.document$(this.sgid,pdfdoc.res_id).subscribe((res: any): void => {
    saveAs(res, pdfdoc.name);
    });
  }




  segmentChanged(ev: any) {
    console.log('Segment changed', ev);
  }

}
