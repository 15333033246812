import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  user:any;
  password1;
  password2;
  constructor( public api:ApiService,public dialogRef: MatDialogRef<ChangepasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log('user',data.user);
      this.user = data.user;
    }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  changePassword() {
    this.api.changeUserPassword(this.password1).subscribe(res=> {
      console.log('changeUserPassword',res);
    })
    this.dialogRef.close();
  }

}
