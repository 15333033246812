import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { ModalController } from '@ionic/angular';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { AddsalesComponent } from '../../components/addsales/addsales.component';
import {ApiService} from '../../services/api.service';
import { ListAnimationX, FadeDownAnimation, FadeUpAnimation } from '../../../animations/basic.animations';

import { environment as env } from '../../../environments/environment';
import { LoaderService } from '../../services/loader.service';
import { UtilsService } from '../../services/utils.service';
import { AddmanagerComponent } from '../../components/addmanager/addmanager.component';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    ListAnimationX,
    FadeDownAnimation,
    FadeUpAnimation
  ]
})
export class HomeComponent {
  projectSales = [];
  grade = 0;
  distributionSales = [];
  managersSales = [];
  specificationSales = [];
  isLoading = true;
  envUrl = env.envKey;
  constructor(
    private modalController:ModalController,
    public auth: AuthService,
    private router : Router,
    private api_service:ApiService,
    public utils:UtilsService,
    private _loader:LoaderService) {
    }

    ngOnInit() {
      this.getData();
    }

    miseajour(e) {
      this.getData();
    }

    getData() {
    //  this._loader.showLoader('Wait while loading');
    this._loader.presentLoading('Wait while loading').then(()=>{
      this.auth.user$.subscribe(res => {
        //this._loader.hideLoader();
        this.isLoading = false;
        this.grade = res[this.envUrl + '/grade_user'];
        console.log('grade',this.grade);
        if (this.grade == 1) {
          this.api_service.commercials$().subscribe(res=> {
            let arrayRes = res.filter(p => p.archive == -1) ;

            arrayRes = arrayRes.filter(p => p.grade_id == 2) ;
            this.projectSales = arrayRes.filter(p => p.role_id==2) ;
            this.distributionSales= arrayRes.filter(p => p.role_id==1);
            this.specificationSales= arrayRes.filter(p => p.role_id==3);

          })
        }

        if (this.grade == 3) {
          this.api_service.directorinfo$().subscribe(res=> {
            this.managersSales = res.managers;
          console.log('director info',res);

          })
        }

        this._loader.hideLoader();

    })})

    }



    async presentModalAddManager() {
      const modal = await this.modalController.create({
        component: AddmanagerComponent,
        cssClass: 'my-custom-class',
      });
      return await modal.present();
    }


  async presentModalAddSales() {
    const modal = await this.modalController.create({
      component: AddsalesComponent,
      cssClass: 'my-custom-class',
    });
    return await modal.present();
  }



}
