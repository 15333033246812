<ion-content>
    <ion-button (click)="openDialogChangePassword(33)" color="primary" size="small" >Change password</ion-button>
    <ion-item>
        <ion-label>
            <h4>Country : {{country?.country}}</h4>
        </ion-label>
    </ion-item>
<ion-item>
    <ion-label>
        <h4>Sales Director : {{country?.sales_director?.first_name}} {{country?.sales_director?.last_name}}</h4>
    </ion-label>
</ion-item>
<!--
<ion-list *ngIf="country">
    <ion-list-header>Sales Manager</ion-list-header>
    <ion-item *ngFor="let salesm of country?.salesmanagers">
        <ion-label>

            {{salesm?.username}}
          <h5>{{salesm?.first_name}} {{salesm?.last_name}}</h5>

        </ion-label>
    </ion-item>
</ion-list> -->

<ion-accordion-group>
    <ion-accordion *ngFor="let salesm of country?.salesmanagers" [value]="salesm.id_user">
      <ion-item slot="header" color="light">
        <ion-label>Sales manager : {{salesm?.first_name}} {{salesm?.last_name}}
            <p>{{salesm?.username}}</p>
        </ion-label>
        <ion-button color="primary" size="small" >Change password</ion-button>
      </ion-item>
      <div class="ion-padding" slot="content">
       <ion-list>
        <ion-item *ngFor="let sales of salesm?.sales">
            <ion-label>
                <h4>{{sales?.first_name}} {{sales?.last_name}}</h4>
                <p>{{sales?.role_name}}</p>
            </ion-label>
            <ion-button (click)="openDialogChangePassword(sales)" color="warning" size="small" >Change password</ion-button>
        </ion-item>
        <ion-item *ngIf="salesm?.sales.length == 0">
            <ion-label>
                <h4>No sales</h4>
            </ion-label>
        </ion-item>
       </ion-list>
      </div>
    </ion-accordion>
</ion-accordion-group>


</ion-content>
