<!--
<ion-content>

    <div *ngIf="type_add == 'priorities'">
    <ion-item>
        <ion-label>List of skills</ion-label>
        <ion-select placeholder="Select One" [(ngModel)]="selected_family">
          <ion-select-option  *ngFor="let family of data;let i = index;" value="{{i}}">{{family.family_skill_name}}</ion-select-option>
        </ion-select>
      </ion-item>
      <div>

          <ion-badge cdkDrag style="margin:2%;" *ngFor="let subfamily of data[selected_family].subskills">
              {{subfamily.skill_name}}
          </ion-badge>

          <div
          cdkDropList
          [cdkDropListData]="todo"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let item of todo" cdkDrag>{{item}}</div>

      </div>
      <div class="example-container">
        <h2>Done</h2>

        <div
          cdkDropList
          [cdkDropListData]="done"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let item of done" cdkDrag>{{item}}</div>
        </div>
      </div>
        </div>

    </div>
</ion-content>
<ion-footer>
    <ion-button (click)="closeModal()">Close Modal</ion-button>

</ion-footer> -->
<ion-header>
  <ion-navbar>
      <ion-title *ngIf="type_add == 'priorities'">Priorities</ion-title>
      <ion-title *ngIf="type_add == 'actionplan'">Action plan</ion-title>
      <ion-title *ngIf="type_add == 'coachingsession'">New coaching session</ion-title>
      <ion-buttons slot="end">
          <button ion-button icon-only (click)="closeModal()">
              <ion-icon  name="ios-close-outline"></ion-icon>
          </button>
      </ion-buttons>
  </ion-navbar>
</ion-header>
<ion-content>
  <ion-item>
    <ion-label>
        <h5 *ngIf="type_add == 'priorities'">Select skills priorities</h5>
        <h5 *ngIf="type_add == 'actionplan'">Action plan</h5>
        <h5 *ngIf="type_add == 'coachingsession'">New coaching session</h5>
        <p *ngIf="type_add == 'priorities'">
            Drag & Drop skills group by family to set them as priorities
         </p>
    </ion-label>
</ion-item>
<ion-item>
  <ion-label>List of skills</ion-label>
  <ion-select placeholder="Select One" [(ngModel)]="selected_family">
    <ion-select-option  *ngFor="let family of data;let i = index;" value="{{i}}">{{family.family_skill_name}}</ion-select-option>
  </ion-select>
</ion-item>
<div cdkDropListGroup *ngIf="type_add == 'priorities'" style="padding:10px;">
  <div class="example-container">
    <div
      cdkDropList
      [cdkDropListData]="data[selected_family].subskills"
      class="example-list"
      (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let item of data[selected_family].subskills" cdkDrag>{{item.skill_name}}</div>
    </div>
  </div>

  <div class="example-container">
    <h5>1st priorities</h5>
    <div
      cdkDropList
      [cdkDropListData]="first_prio"
      class="example-list"
      style="min-height:20px;"
      (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let item of first_prio" cdkDrag>{{item.skill_name}}</div>
    </div>
  </div>
  <div class="example-container">
    <h5>2nd priorities</h5>
    <div
      cdkDropList
      [cdkDropListData]="second_prio"
      class="example-list"
      style="min-height:20px;"
      (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let item of second_prio" cdkDrag>{{item.skill_name}}</div>
    </div>
  </div>
  <div class="example-container">
    <h5>3rd priorities</h5>
    <div
      cdkDropList
      [cdkDropListData]="third_prio"
      class="example-list"
      style="min-height:20px;"
      (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let item of third_prio" cdkDrag>{{item.skill_name}}</div>
    </div>
  </div>
</div>
</ion-content>
