<div>
    <div class="ion-text-end">
        <ion-button size="small" (click)="close()" tabindex="-1">Close</ion-button>
    </div>


    <h1>Change password</h1>
    <p>This will change and replace the current user password</p>
    <p>Username : {{user?.username}}</p>


    <ion-item>
        <ion-label position="stacked">New password</ion-label>
        <ion-input  [(ngModel)]="password1" type="password"></ion-input>
    </ion-item>
    <ion-item>
        <ion-label position="stacked">Retype new password</ion-label>
        <ion-input [(ngModel)]="password2" type="password"></ion-input>
    </ion-item>

    <ion-button [disabled]="(password1 != password2) || !password1  || !password2 || password1.length < 6 || password1.length > 12" size="small" (click)="changePassword()">Change user password</ion-button>


</div>


